<template>
  <div>
    <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false">
      <div style="flex-direction: column; text-align: center; z-index: 998">
        <div class="loading-icon" />
        <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
          Loading Data
        </div>
      </div>
    </b-loading>
    <div v-if="!loading" class="is-fullwidth">
      <div class="hide-for-print">
        <div id="datasheet_builder" class="topbar">
          <div class="padding-xy-2 is-flex-tablet" style="align-items: stretch">
            <div class="field">
              <label v-if="connectionSelect">Connection</label>
              <b-select v-model="connectionSelect" expanded placeholder="Connection">
                <option disabled="disabled" :value="null">
                  Connection
                </option>
                <option v-for="c in connections" :key="c.id" :value="c.id">
                  {{ c.name }}
                </option>
              </b-select>
            </div>
            <div class="field">
              <label v-if="sizeSelect">Size</label>
              <b-select v-if="connectionSelect" v-model="sizeSelect" expanded placeholder="Size">
                <option disabled="disabled" :value="null">
                  Size
                </option>
                <option v-for="s in sizesFiltered" :key="s.id" :value="s.id">
                  {{ s.size }}
                </option>
              </b-select>
              <b-select v-else expanded placeholder="Size" disabled>
                <option disabled="disabled" :value="null">
                  Size
                </option>
              </b-select>
            </div>
            <div class="field">
              <label v-if="weightSelect">Weight</label>
              <b-select v-if="sizeSelect" v-model="weightSelect" expanded placeholder="Weight">
                <option disabled="disabled" :value="null">
                  Weight
                </option>
                <option v-for="w in weightsFiltered" :key="w.id" :value="w.id">
                  {{ w.weight }}
                </option>
              </b-select>
              <b-select v-else expanded placeholder="Weight" disabled>
                <option disabled="disabled" :value="null">
                  Weight
                </option>
              </b-select>
            </div>
            <div class="field">
              <label v-if="couplingSelect">Coupling Type</label>
              <b-select v-if="weightSelect" v-model="couplingSelect" expanded placeholder="Coupling Type" :disabled="!weightSelect">
                <option disabled="disabled" :value="null">
                  Coupling Type
                </option>
                <option v-for="couple in couplingData" :key="couple.id" :value="couple.id">
                  {{ couple.name }}
                </option>
              </b-select>
              <b-select v-else expanded placeholder="Coupling Type" disabled>
                <option disabled="disabled" :value="null">
                  Coupling Type
                </option>
              </b-select>
            </div>
            <div class="field">
              <div class="columns is-mobile" style="margin: 0px">
                <div class="column has-text-centered is-6" style="padding: 0 5px 0 0;">
                  <b-button :disabled="!couplingSelect" class="is-success padding-y-2 is-fullwidth" style="height: auto" @click="createPDF()">
                    <div><b-icon icon="download" /></div>
                    <div style="line-height: 1.1">
                      <b>Download</b><br> <small>Compressed</small>
                    </div>
                  </b-button>
                </div>
                <div class="column has-text-centered is-6" style="padding: 0 0 0 5px;">
                  <b-button :disabled="!couplingSelect" class="is-success padding-y-2 is-fullwidth" style="height: auto" @click="printPdf()">
                    <div><b-icon icon="print" /></div>
                    <div style="line-height: 1.1">
                      <b>Print</b> <br> <span style="white-space: nowrap"><small>Full-resolution</small></span>
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
            <!-- <div class="field is-hidden-mobile" style="flex: 0">
              <b-button icon-left="undo" type="is-transparent has-text-grey-light" style="height: 100%; padding: 0;" @click="resetSheet">
                Reset
              </b-button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="is-fullwidth" style="overflow: auto;">
        <div id="gauge_sheet_wrapper" class="width-1000">
          <div id="gauge_sheet_page">
            <div id="pdf_wrapper" style="padding: 0">
              <div class="padding-y-4 padding-x-2">
                <div class="has-text-weight-bold is-size-4 has-text-centered margin-y-1">
                  Blanking Dimensions for Threaded Accessories
                </div>
                <div class="has-text-centered">
                  <div v-if="connectionSelect" style="font-size: 18px" class="columns is-vcentered is-centered">
                    <div v-if="imageConnection" class="column is-narrow">
                      <img :src="imageConnection.screenshotUrl" style="width: 120px; margin-top: 5px" crossOrigin="anonymous">
                    </div>
                    <div v-if="sizeSelect" class="column is-narrow" style="margin-left: 15px">
                      <span class="has-text-weight-light" style="margin-right: 5px">Size:</span>
                      <span class="has-text-weight-bold">{{ size.size }}</span>
                    </div>
                    <div v-if="weightSelect" class="column is-narrow" style="margin-left: 15px">
                      <span class="has-text-weight-light" style="margin-right: 5px">Weight:</span>
                      <span class="has-text-weight-bold">{{ weight.weight }} lb/ft</span>
                    </div>
                    <div v-if="couplingSelect" class="column is-narrow" style="margin-left: 15px">
                      <span class="has-text-weight-light" style="margin-right: 5px">Coupling:</span>
                      <span class="has-text-weight-bold is-capitalized">{{ couplingText }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div id="blanking_dimensions_wrapper">
                <div id="dimensions_wrapper">
                  <div id="pin" class="big">
                    PIN
                  </div>
                  <div id="box" class="big">
                    BOX
                  </div>
                  <div id="pin_typical">
                    <strong>R</strong> {{ (weight && weight.threadRecutLen !== null) ? (weight.threadRecutLen + '" (typical)') : '&nbsp;' }}
                  </div>
                  <div id="pin_typical_description">
                    <strong>35° (typical)</strong>
                    Recommended but subject to specific customer requirements
                  </div>
                  <div id="pin_turning_length">
                    <label>Boring/Turning Length</label>
                    <div>
                      {{ (weight && weight.dimTurnLenPin !== null) ? (`${'Min '}${weight.dimTurnLenPin}${'"'}`) : '&nbsp;' }}
                    </div>
                  </div>

                  <div id="wall_thickness">
                    <label>Wall Thickness</label>
                    {{ (weight && weight.pipeBodyWall !== null) ? (weight.pipeBodyWall + '"') : '&nbsp;' }}
                  </div>

                  <div id="box_turning_length">
                    <label>Boring/Turning Length</label>
                    <div>
                      {{ (weight && weight.dimTurnLenBox !== null) ? ('Min ' + weight.dimTurnLenBox + '"') : '&nbsp;' }}
                    </div>
                  </div>

                  <div id="pin_boring">
                    <label>Boring Diameter</label>
                    <div class="top">
                      {{ (weight && weight.dimBorDiameterPin !== null) ? (weight.dimBorDiameterPin + '"') : '&nbsp;' }}
                    </div>
                    <div class="columns">
                      <div class="column">
                        {{ (weight && weight.dimBorDiameterPinMax !== null) ? ( ((weight.dimBorDiameterPinMax > 0) ? '+' : '') + ((weight.dimBorDiameterPinMax === 0) ? '-' : '') + weight.dimBorDiameterPinMax.toFixed(4) + '"') : '&nbsp;' }}
                      </div>
                      <div class="column">
                        {{ (weight && weight.dimBorDiameterPinMin !== null) ? ( ((weight.dimBorDiameterPinMin > 0) ? '+' : '') + ((weight.dimBorDiameterPinMin === 0) ? '-' : '') + weight.dimBorDiameterPinMin.toFixed(4) + '"') : '&nbsp;' }}
                      </div>
                    </div>
                  </div>
                  <div id="turning_diameter">
                    <label>Turning Diameter</label>
                    <div class="top">
                      <div class="columns">
                        <div class="column">
                          Pin {{ (weight && weight.dimTurnDiameterPin !== null) ? (weight.dimTurnDiameterPin + '"') : '&nbsp;' }}
                        </div>
                        <div v-if="!couplingSelect" class="column">
                          Box
                        </div>
                        <div v-if="couplingSelect" class="column">
                          Box <span>{{ (weight && couplingById[couplingSelect] && couplingById[couplingSelect].cplgOd !== null) ? (couplingById[couplingSelect].cplgOd + '"') : '&nbsp;' }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        {{ (weight && weight.dimTurnDiameterPinMax !== null) ? ( ((weight.dimTurnDiameterPinMax > 0) ? '+' : '') + ((weight.dimTurnDiameterPinMax === 0) ? '-' : '') + weight.dimTurnDiameterPinMax.toFixed(4) + '"') : '&nbsp;' }}
                      </div>
                      <div class="column">
                        {{ (weight && weight.dimTurnDiameterPinMin !== null) ? ( ((weight.dimTurnDiameterPinMin > 0) ? '+' : '') + ((weight.dimTurnDiameterPinMin === 0) ? '-' : '') + weight.dimTurnDiameterPinMin.toFixed(4) + '"') : '&nbsp;' }}
                      </div>
                      <div class="column">
                        <span v-if="couplingSelect">{{ (weight && couplingById[couplingSelect] && couplingById[couplingSelect].upperTolMax !== null) ? (((couplingById[couplingSelect].upperTolMax > 0) ? '+' : '') + ((couplingById[couplingSelect].upperTolMax === 0) ? '-' : '') + couplingById[couplingSelect].upperTolMax.toFixed(4) + '"') : '&nbsp;' }}</span>
                      </div>
                      <div class="column">
                        <span v-if="couplingSelect">{{ (weight && couplingById[couplingSelect] && couplingById[couplingSelect].lowerTolMin !== null) ? (((couplingById[couplingSelect].lowerTolMin > 0) ? '+' : '') + ((couplingById[couplingSelect].lowerTolMin === 0) ? '-' : '') + couplingById[couplingSelect].lowerTolMin.toFixed(4) + '"') : '&nbsp;' }}</span>
                      </div>
                    </div>
                  </div>

                  <div id="box_boring">
                    <label>Boring Diameter</label>
                    <div class="top">
                      {{ (weight && weight.dimBorDiameterBox !== null) ? (weight.dimBorDiameterBox + '"') : '&nbsp;' }}
                    </div>
                    <div class="columns">
                      <div class="column">
                        {{ (weight && weight.dimBorDiameterBoxMax !== null) ? (((weight.dimBorDiameterBoxMax > 0) ? '+' : '') + ((weight.dimBorDiameterBoxMax === 0) ? '-' : '') + weight.dimBorDiameterBoxMax.toFixed(4) + '"') : '&nbsp;' }}
                      </div>
                      <div class="column">
                        {{ (weight && weight.dimBorDiameterBoxMin !== null) ? (((weight.dimBorDiameterBoxMin > 0) ? '+' : '') + ((weight.dimBorDiameterBoxMin === 0) ? '-' : '') + weight.dimBorDiameterBoxMin.toFixed(4) + '"') : '&nbsp;' }}
                      </div>
                    </div>
                  </div>

                  <div id="thread_recut_pin">
                    <label>Pin re-cut extra length</label>
                    <div style="flex: 1">
                      {{ (weight && weight.threadRecutLenExtraPin !== null) ? (weight.threadRecutLenExtraPin + '"') : '&nbsp;' }}
                    </div>
                  </div>
                  <div id="thread_recut_box">
                    <label>Box re-cut extra length</label>
                    <div style="flex: 1">
                      {{ (weight && weight.threadRecutLenExtraBox !== null) ? (weight.threadRecutLenExtraBox + '"') : '&nbsp;' }}
                    </div>
                  </div>
                </div>
                <img id="blanking_table_image" src="@/assets/images/blanking_dimensions/blankingDimensionsTable.png">
              </div>
              <div class="has-text-centered padding-y-4 padding-x-2" style="font-size: 13px;">
                This document is for informational purposes only. All values must be verified using the latest JFE blanking procedures.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" :active.sync="preparingFile" :can-cancel="false">
      <div style="flex-direction: column; text-align: center; z-index: 998">
        <div class="loading-icon" />
        <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
          Preparing your file
        </div>
      </div>
    </b-loading>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Moment from 'moment'
import Vue2Filters from 'vue2-filters'

export default {
  name: 'BlankingDimensionsGenerator',
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      loading: true,
      connectionSelect: null,
      couplingSelect: null,
      sizeSelect: null,
      weightSelect: null,
      preparingFile: false
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    connections () {
      return this.$store.state.connectionsLight.filter(c => c.blankingAvailability)
    },
    connectionsById () {
      const mapped = {}

      this.connections.forEach(connection => {
        mapped[connection.id] = connection
      })

      return mapped
    },
    couplingById () {
      const mapped = {}
      this.weight.couplings.forEach(c => {
        mapped[c.id] = c
      })

      return mapped
    },
    dateHeader () {
      return Moment().format('MMM D, YYYY')
    },
    connection () {
      return this.connections.find(c => c.id === this.connectionSelect)
    },
    imageConnection () {
      return this.connections.find(c => c.id === this.connectionSelect).images.find(i => i.name === 'Logo')
    },
    size () {
      return this.sizesFiltered.find(s => s.id === this.sizeSelect)
    },
    sizesFiltered () {
      this.changeSheet('default')
      if (this.connectionSelect) {
        return this.connections.find(c => c.id === this.connectionSelect).sizes.filter(s => !s.deleted)
      } else {
        return null
      }
    },
    weight () {
      if (this.weightSelect) {
        return this.weightsFiltered.find(w => w.id === this.weightSelect)
      } else {
        return null
      }
    },
    weightsFiltered () {
      this.changeSheet('weight')
      if (this.sizeSelect) {
        return this.sizesFiltered.find(s => s.id === this.sizeSelect).weights.filter(w => !w.deleted) || null
      } else {
        return null
      }
    },
    couplingText () {
      return this.couplingById[this.couplingSelect] ? this.couplingById[this.couplingSelect].name : null
    },
    couplingData () {
      return this.orderCouplilngVetor(this.weight.couplings.filter(c => c.blankingDimensionsEnabled))
    }
  },
  async mounted () {
    this.$store.commit('setApp', 'blanking')
    this.$store.commit('setTitle', 'Blanking Dimensions')
    await this.$store.dispatch('refreshConnectionsLight', { hasImages: true, hasSizes: true, hasWeights: true })
    this.loading = false
  },
  methods: {
    resetSheet () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to reset?',
        confirmText: 'Reset Blanking Sheet',
        type: 'is-danger',
        onConfirm: () => {
          this.connectionSelect = null
          this.changeSheet('default')
        }
      })
    },
    changeSheet (field) {
      switch (field) {
        case 'size':
          this.sizeSelect = null
          this.weightSelect = null
          this.couplingSelect = null
          break
        case 'weight':
          this.weightSelect = null
          this.couplingSelect = null
          break
        default:
          this.sizeSelect = null
          this.weightSelect = null
          this.couplingSelect = null
      }
    },
    printPdf () {
      this.printingPdf = true
      setTimeout(() => {
        window.print()
        this.printingPdf = false
        // window.onafterprint = function () this.printingPdf = false
      }, 500)
    },
    createPDF (quality = 3) {
      // this.preparingFile = true
      this.$scrollTo('body', 200)
      const filename = this.connection.name + '_' + this.size.size + '_' + this.weight.weight + '_' + this.couplingById[this.couplingSelect].name
      setTimeout(function () {
        html2canvas(document.querySelector('#pdf_wrapper'), {
          scale: quality,
          useCORS: true
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/png')
          // eslint-disable-next-line
          const pdf = new jsPDF('landscape', 'in', 'letter')
          const imgProps = pdf.getImageProperties(imgData)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST')
          pdf.save(filename + '_blanking-dimensions.pdf')
        })
        this.preparingFile = false
      }, 300)
    }
  }
}
</script>
<style lang="scss">
  @page {
    size: landscape;
  }
</style>
